.new-review {
    & .section-wrap-content {
        min-height: 15rem;

        & .new-review-card-wrap {
            width: 100%;
            max-width: max-content;
            background-color: var(--card-color);
            padding: 2rem;
            gap: 1rem;

            &-header {
                display: flex;
                justify-content: space-between;
                gap: 0.5rem;
                align-items: center;
                margin-bottom: 0.5rem;

                &-grade {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    height: 100%;
                    &-controls {
                        height: 100%;
                        border: none;
                        width: 2.5rem;
                        font-weight: 700;
                        background-color: var(--cta-card-color);
                        color: rgb(219, 145, 7);
                        font-size: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.disabled {
                            opacity: 0.6;
                        }

                        &:hover{
                            cursor: pointer;
                        }
                    }

                    & .stars-notation-wrap {
                        width: 5rem;
                        justify-content: center;
                    }
                }

                &-actions {
                    display: flex;
                    gap: 0.5rem;
                    height: 100%;

                    &-validate,
                    &-close {
                        height: 2.3rem;
                        width: 2.3rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        aspect-ratio: 1/1;

                        & svg {
                            height: 0.75rem;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    &-close {
                        background-color: var(--error-color);
                        stroke: var(--text-error-color);
                    }

                    &-validate {
                        background-color: var(--main-color);
                        stroke: var(--text-cta-color);
                    }
                }
            }

            & input,
            & textarea {
                background-color: transparent;
                border: none;
                resize: none;
                outline: none;

                &::placeholder {
                    color: var(--third-text-color);
                }
            }

            & input {
                color: var(--primary-text-color);
                font-family: Acorn;
                font-size: 2rem;
                font-style: normal;
                font-weight: 900;
                line-height: 1.375rem; /* 68.75% */
            }

            & textarea {
                color: var(--secondary-text-color);
                font-family: "DM Sans";
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.375rem;
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .new-review-card-wrap-header{
        flex-wrap: wrap;
        & .stars-notation-wrap{
            & p {
                font-size: 1rem;
            }
            
        }
    }
}
