.hero-banner-wrap{
    display: flex;
    gap: 3rem;
    width: 100%;
    transform-origin: center;
    height: 110vh;
    position: relative;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    

    &-background{
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;

        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        
    }

    &-overlays{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        &-left,&-top,&-bottom{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;  
        }

        &-left{
            background: linear-gradient(83deg, rgba(3, 7, 18, 0.80) 21.48%, rgba(3, 7, 18, 0.80) 36.25%, rgba(3, 7, 18, 0.00) 81.21%);
        
        }

        &-top{
            background: linear-gradient(0deg, rgba(3, 7, 18, 0.00) 40.99%, #030712 99.92%);
            height: 50%;
        }

        &-bottom{
            background: linear-gradient(180deg, rgba(3, 7, 18, 0.00) 43.77%, rgba(3, 7, 18, 0.67) 50.71%, rgba(3, 7, 18, 0.87) 59.4%, #030712 69.54%);


        }
    }
}

@media only screen and (max-width:767px) {
    .hero-banner-wrap-background img{
        opacity: .7;
    }

}