.profile-wrap {
    width: 100%;
    height: 100%;
    padding: 8rem 0rem 5rem 0rem;
    gap: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .footer-wrap{
        padding-left: 3.625rem;
        padding-right: 3.625rem;
    }
    


    & .hero-banner-wrap {
        & img {
            object-fit: cover;
            transform: scale(1.4);
            opacity: 0.5;
        }
    }
    &-header {
        display: flex;
        justify-content: space-between;

        width: 100%;
        z-index: 2;
        padding: 0 3.625rem;
        flex-wrap: wrap;

        & .leftHeader {
            display: flex;
            align-items: center;
            gap: 2rem;

            &-profile-picture {
                position: relative;
                height: 9rem;
                width: 9rem;
                & img {
                    height: 100%;
                    width: 100%;
                    border-radius: 15rem;
                    object-fit: cover;
                }

                & .image-loader{
                    border-radius: 15rem;
                }

                & input{
                    display: none;
                }

                &-change{
           
                    padding: .5rem;
                    position: absolute;
                    border-radius: 10rem;
                    height: 2rem;
                    width: 2rem;
                    aspect-ratio: 1/1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    right: 0;
                    bottom: 0;
                    transform: translate(-20%,-20%);
                     & svg {
                        height: 1rem;
                     }
        
                     &:hover{
                        cursor: pointer;
                    }
                }
        
                &-change{
                    background-color: var(--primary-text-color);
                    color: var(--card-color);
        
                    &:hover{
                        background-color: var(--main-color);
                        color: var(--primary-text-color);
                    }
        
                    
                }
            }

            &-infos {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                &-username {
                    color: var(--secondary-text-color);
                    font-size: 1.25rem;
                    font-weight: 500;
                    line-height: 1.25rem;
                }

                &-name {
                    color: var(--primary-text-color);
                    font-family: Acorn;
                    font-size: 2.5rem;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 2.5rem; /* 100% */
                }

                &-footer {
                    display: flex;
                    gap: 0.5rem;

                    &-edit-profile {
                        border: none;
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        padding: 0.8rem 1rem;

                        width: -moz-fit-content;
                        width: fit-content;
                        transition: all 0.4s;
                        border-radius: var(--default-radius);

                        & svg {
                            height: 1rem;
                        }

                        & p {
                            font-size: 0.8rem;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }

                    &-edit-profile {
                        background-color: var(--main-color);
                        color: var(--text-cta-color);

                        &:hover {
                            cursor: pointer;
                            color: var(--main-color);
                            background-color: var(--text-cta-color);
                        }
                    }
                }
            }
        }

        & .rightHeader {
            display: flex;
            flex-direction: column;
            gap: 1.25rem;

            &-item {
                display: flex;
                flex-direction: column;
                gap: 0rem;
                color: var(--main-color);
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.5rem; /* 150% */
                align-items: end;

                &-title {
                    color: var(--third-text-color);
                    font-size: 0.9rem;
                }

                &-content {
                    color: var(--secondary-text-color);
                    font-weight: 900;
                }

                &-logout {
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.5rem;
                    padding: 0.8rem 1rem;
                    width: 100%;
                    transition: all 0.4s;
                    background-color: var(--error-color);
                    color: var(--text-error-color);
                    border-radius: var(--default-radius);

                    &:hover {
                        cursor: pointer;
                        color: var(--error-color);
                        background-color: var(--text-error-color);
                    }
                    & svg {
                        height: 1rem;
                    }

                    & p {
                        font-size: 0.8rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }

            &-member-date {
                color: var(--third-text-color);
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.5rem; /* 150% */
            }
        }
    }

    & .section-wrap.articles.user {
        z-index: 2;

        & .section-wrap-header{
            padding-left: 3.625rem;
            
        }

        & .section-wrap-content{
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            width: calc(100%);
            display: grid;
            grid-auto-flow: column;
            gap: 1rem;
            justify-content: start;

            & .article-card-wrap{
                scroll-snap-align: center;
                width: 25rem;

                &:first-child{
                    padding-left: 3.625rem;
                    width: calc(25rem + 3.625rem);
                }


                &:last-child{
                    margin-right: 3.625rem;
                }
            }

            
        }
    }

    & .reviews{
        padding: 0 3.625rem;
        z-index: 2;
    }
}

@media only screen and (max-width:767px) {
    .profile-wrap {
        padding-bottom: 8rem;
        & .footer-wrap{
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    
        
        & .hero-banner-wrap {
            
            & img {
                object-fit: cover;
                transform: scale(1);
                opacity: .7;
            }
        }
        &-header {
            gap: 2rem;
            padding: 0 1.5rem;
    
            & .leftHeader {
                flex-direction: column;
                align-items: start;
    
                &-profile-picture {
                    width: 6rem;
                    height: 6rem;
    
                    &-change{
                        transform: translate(-5%,-5%);
                        aspect-ratio: 1/1;
                    }
                }
    
                &-infos {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
    
                    &-name {
                        font-size: 2rem;
                        line-height: 2rem;
                    }
    
                    &-footer {
                        display: flex;
                        gap: 0.5rem;
                    }
                }
            }
    
            & .rightHeader {
                gap: 1rem;

                &-item{
                    align-items: start;
                }
            }
        }
    
        & .section-wrap.articles.user {
            z-index: 2;

            & .section-wrap-header{
                padding-left: 1.5rem;
            }

            & .section-wrap-content{
                & .article-card-wrap{
                    width: 15rem;

                    &:first-child{
                        padding-left: 1.5rem;
                        width: calc(15rem + 1.5rem);
                    }
    
    
                    &:last-child{
                        margin-right: 1.5rem;
                    }
                }
            }

           

        }

        & .reviews{
            padding: 0 1.5rem;
        }

    }
}
