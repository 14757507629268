.section-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1rem;

        &-title {
            font-size: 1.5rem;
            color: var(--secondary-text-color);
        }
    }

    &-content {
        display: flex;
        gap: 1.5rem;
        height: fit-content;
        overflow-y: hidden;

        &.empty {
            grid-template-columns: 1fr;

            & .message-banner-wrap {
                margin: 0;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .section-wrap {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-title {
                font-size: 1.25rem;
                color: var(--primary-text-color);
            }
        }
    }
}
