@font-face {
    font-family: 'Acorn';
    src: url('Acorn-Light.eot');
    src: local('Acorn Light'), local('Acorn-Light'),
        url('Acorn-Light.eot?#iefix') format('embedded-opentype'),
        url('Acorn-Light.woff2') format('woff2'),
        url('Acorn-Light.woff') format('woff'),
        url('Acorn-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acorn';
    src: url('Acorn-ExtraLight.eot');
    src: local('Acorn ExtraLight'), local('Acorn-ExtraLight'),
        url('Acorn-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Acorn-ExtraLight.woff2') format('woff2'),
        url('Acorn-ExtraLight.woff') format('woff'),
        url('Acorn-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acorn';
    src: url('Acorn-Bold.eot');
    src: local('Acorn Bold'), local('Acorn-Bold'),
        url('Acorn-Bold.eot?#iefix') format('embedded-opentype'),
        url('Acorn-Bold.woff2') format('woff2'),
        url('Acorn-Bold.woff') format('woff'),
        url('Acorn-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acorn';
    src: url('Acorn-Medium.eot');
    src: local('Acorn Medium'), local('Acorn-Medium'),
        url('Acorn-Medium.eot?#iefix') format('embedded-opentype'),
        url('Acorn-Medium.woff2') format('woff2'),
        url('Acorn-Medium.woff') format('woff'),
        url('Acorn-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acorn';
    src: url('Acorn-Thin.eot');
    src: local('Acorn Thin'), local('Acorn-Thin'),
        url('Acorn-Thin.eot?#iefix') format('embedded-opentype'),
        url('Acorn-Thin.woff2') format('woff2'),
        url('Acorn-Thin.woff') format('woff'),
        url('Acorn-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acorn';
    src: url('Acorn-Regular.eot');
    src: local('Acorn Regular'), local('Acorn-Regular'),
        url('Acorn-Regular.eot?#iefix') format('embedded-opentype'),
        url('Acorn-Regular.woff2') format('woff2'),
        url('Acorn-Regular.woff') format('woff'),
        url('Acorn-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acorn';
    src: url('Acorn-SemiBold.eot');
    src: local('Acorn SemiBold'), local('Acorn-SemiBold'),
        url('Acorn-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Acorn-SemiBold.woff2') format('woff2'),
        url('Acorn-SemiBold.woff') format('woff'),
        url('Acorn-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

