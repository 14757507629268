.home-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    

    &-content{
        padding: 8rem 0rem 5rem 0rem;
        gap: 3rem;
        display: flex;
        z-index: 4;
        flex-direction: column;
        align-items: start;
        max-width: calc(100%);
        width: 100%;
        overflow: hidden;
        
    }

    & .footer-wrap{
        padding-left: 3.625rem;
        padding-right: 3.625rem;
    }
}

.categories-section{
    display: flex;
    gap: 1.5rem;
    width: 100%;
}

@media only screen and (max-width:767px) {
    .home-wrap{
        &-content{
            padding: 8rem 0rem 8rem 0rem;
        }
        & .footer-wrap{
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    } 
}