.dropdown-wrap{
    position: relative;

    &-button{
        width: 12rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: .1rem solid var(--disabled-color);
        padding: .5rem 1rem;
        height: 100%;
        border-radius: .5rem;
        background-color: var(--card-color);
        
        

        & svg, & p{
            color: var(--secondary-text-color);
            fill: var(--secondary-text-color);
            font-weight: 500;
        }
        &:hover{
            cursor: pointer;
        }
    }

    &-options{
        position: absolute;
        top: 110%;
        width: 100%;
        border: .1rem solid var(--disabled-color);
        border-radius: .5rem;
        overflow: hidden;
        z-index: 9999;

        &-item{
            padding: .88rem .75rem;
            background-color: var(--card-color);
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.25rem; /* 125% */
            color: var(--secondary-text-color);
            text-align: center;
            font-weight: 500;

            &:hover{
                background-color: var(--main-color);
                color: var(--text-cta-color);
                cursor: pointer;
            }

            &.active{
                background-color: var(--main-color);
                color: var(--text-cta-color);
            }
        }
    }
}