.games {
    & .section-wrap-header{
        padding-left: 3.625rem;
    }

    & .section-wrap-content {
        gap: 1rem;
        transition: all 1s;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        width: calc(100%);
        display: grid;
        grid-auto-flow: column;
    
        & .game-card-wrap{
            scroll-snap-align: center;
            width: 18rem;
            flex: 0 0 18rem;

            &:first-child{
                padding-left: 3.625rem;
                width: calc(18rem + 3.625rem);
            }


            &:last-child{
                margin-right: 3.625rem;
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .games {
        & .section-wrap-header{
            padding-left: 1.5rem;
        }
    
        & .section-wrap-content {
            gap: .75rem;
            transition: all 1s;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            width: calc(100%);
            display: grid;
            grid-auto-flow: column;
        
            & .game-card-wrap{
                width: 16rem;
                flex: 0 0 16rem;

                &:hover{
                    transform: none;
                }
    
                &:first-child{
                    padding-left: 1.5rem;
                    width: calc(15rem + 1.5rem);
                }
    
    
                &:last-child{
                    margin-right: 1.5rem;
                }
            }
        }
    }  
}