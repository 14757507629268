.main-article-wrap {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    max-width: 35rem;
    transform-origin: center;
    padding-left: 3.625rem;

    &-header {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        width: 100%;

        &-reading-time{
            display: flex;
            align-items: center;
            gap: .5rem;
            padding: .5rem .75rem;
            background-color: var(--disabled-color);
            height: 100%;
            color: var(--secondary-text-color);
            border-radius: var(--default-radius);
            & svg {
                height: 1.25rem;
            }
            
            & p {
                font-weight: 700;
                font-size: 1rem;
            }
        }
    }

    & .description{
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &-title {
            color: var(--primary-text-color);
            font-family: Acorn;
            font-size: 3rem;
            font-weight: 700;
            width: 100%;
            line-height: 110%;
            transition: all 0.4s;
        }
    
        &-text {
            color: var(--secondary-text-color);
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            white-space: wrap;
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-style: italic;
        }
    }

    

    &-footer {
        display: flex;
        margin-top: .25rem;

        &-cta{
            display: flex;
            height: 2.75rem;
            padding: var(--stringLength, 0rem) 1rem;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            background-color: var(--primary-text-color);
            color: var(--card-color);
            transition: all .4s;
            border-radius: var(--default-radius);
            & p {
                
                font-size: 1rem;
                font-weight: 700;
            }

            & svg {
                height: 1.5rem;
            }

            &:hover{
                color: var(--primary-text-color);
                background-color: var(--main-color);
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .main-article-wrap {
        padding: 1.5rem;
        &-header {
            &-reading-time{
                & svg {
                    height: 1.25rem;
                }
                
                & p {
                    font-weight: 700;
                    font-size: .8rem;
                    
                }
            }
        }
    
        & .description{
            &-title {
                color: var(--primary-text-color);
                font-family: Acorn;
                font-size: 2rem;
            }
        }
    
        
    
        &-footer {
            &-cta{
    
                & svg {
                    height: 1.26rem;
                }
            }
        }
    }
}