.skeleton-article-card-wrap{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    gap: 1.25rem;
    border-radius: var(--default-radius);
    
    &-container{
        display: block;
        width: 100%;
        aspect-ratio: 3/2;
        animation: image-loading 1s linear infinite alternate;
    }

    & .skeleton-description{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        
        &-header{
            height: 1rem;
            width: 60%;
            animation: image-loading 1s linear infinite alternate;
            
        }

        &-title{
            height: 1.25rem;
            width: 90%;
            animation: image-loading 1s linear infinite alternate;
        }

        &-tags{
            display: flex;
            gap: .5rem;
            height: 2rem;
            width: 80%;
            animation: image-loading 1s linear infinite alternate;
        }
        
    }
}

.skeleton-game-card-wrap{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: var(--default-radius);
    
    &-container{
        display: block;
        width: 100%;
        aspect-ratio: 4/5.69;
        animation: image-loading 1s linear infinite alternate;
    }
}

.skeleton-game-details-wrap{
    width: 100%;
    padding: 2rem 0;
    display: flex;
    align-items: start;
    gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 100%;

    &-cover{
        width: 100%;
        aspect-ratio: 4/5.69;
        object-fit: cover;
        animation: image-loading 1s linear infinite alternate;
    }

    & .skeleton-texts{
        display: flex;
        flex-direction: column;
        gap: .75rem;
        overflow: hidden;
    
        &-title{
            height: 2rem;
            width: 40%;
            animation: image-loading 1s linear infinite alternate;
        }

        &-details{
            display: flex;
            gap: 1.5rem;
            height: 3rem;
            width: 80%;
    
            &-item{
                height: 100%;
                width: 100%;
                animation: image-loading 1s linear infinite alternate;
            }
        }

        &-synopsis{
            display: flex;
            flex-direction: column;
            gap: .5rem;
            width: 100%;

            &-line{
                width: 100%;
                height: 1rem;
                animation: image-loading 1s linear infinite alternate;
            }
        }
    
        &-footer{
            display: flex;
            gap: 1rem;
            margin-top: .5rem;
    
            &-platforms{
                display: flex;
                gap: .25rem;
                
                
                &-item,&-grade{
                    height: 3rem;
                    width: 3rem;
                    animation: image-loading 1s linear infinite alternate;
                }

                
            }

            &-grade{
                height: 3rem;
                width: 3rem;
                animation: image-loading 1s linear infinite alternate;
            }
        }
    }

    
}

@media only screen and (max-width:767px) {
    .skeleton-game-details-wrap{
        grid-template-columns: 1fr;

        &-cover{
            max-width: 13rem;
        }

        & .skeleton-texts{
            &-title{
                font-size: 1.75rem;
            }
            &-details{
                flex-direction: column;
                gap: 0;
            }
        }
    }
}