.stars-notation-wrap{
    display: flex;
    gap: .14rem;
    align-items: center;
    gap: .5rem;
    width: -moz-fit-content;
    width:fit-content;
    padding: .5rem .75rem ;
    border-radius: var(--default-radius);
    
    background-color: rgb(219, 145, 7);
    color: var(--primary-text-color);
    & p {
        
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        font-size: 1rem;
    }

    & svg {
        height: 1rem;
    }
}

@media only screen and (max-width:767px) {
    .stars-notation-wrap{
        padding: .5rem .75rem ;
        & p {
            font-size: .8rem;
        }
    }
}