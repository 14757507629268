.overlay-wrap{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(0,0,0,.8);
    display: flex;
    justify-content: center;
}

.popup-delete-wrap{
    background-color: var(--card-color);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 1rem;

    &-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &-title{
            font-size: 1.75rem;
            line-height: 1.75rem;
            color: var(--secondary-text-color);
        }
    }

    &-actions{
        display: flex;
        gap: .5rem;
        align-items: center;
        width: 100%;

        &-cancel,&-cta{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .5rem;
            padding: 1rem;
            width: 100%;
            transition: background-image .4s;

            &:hover{
                cursor: pointer;
                background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
            }

            & p{
                font-size: 1rem;
                font-weight: 700;
            }
        }

        &-cancel{
            background-color: #212636;
            color: var(--secondary-text-color);
        }

        &-cta{
            background-color: var(--error-color);
            color: var(--text-error-color);
            
        }
    }
}

.popup-delete-wrap-content{
    display: flex;
    gap: 1.5rem;

    & .review-card-wrap{
        max-width: 25rem;
        padding:1.5rem;
        border-radius: .5rem;
        background-color: var(--cta-card-color);
        
    }

    & .article-card-wrap{
        max-width: 28rem;
        &-cover{
            aspect-ratio: 10/5;
            border-radius: .5rem;
            overflow: hidden;
            & img {
                object-position: center;
                object-fit: cover;
                width: 100%;
            }
            
        }

        & .description-title{
            color: var(--secondary-text-color);
        }
    }

    
}

@media only screen and (max-width:767px) {
    .popup-delete-wrap{
        padding: 1.5rem;
        padding-bottom: 2rem;
        gap: 1.5rem;
        border-radius: 1.5rem 1.5rem 0 0;
        animation: slideInUp .4s;
        border: none;

        &-header{
            &-title{
                font-size: 1.25rem;
            }
        }
    }
}