.footer-wrap{
    padding: 3.8125rem 0rem 0rem 0rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    &-content{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        &-texts{
            display: flex;
            flex-direction: column;
            gap: .5rem;

            &-title{
                color: var(--primary-text-color);
                font-family: Acorn;
                font-size: 1.5rem;
                font-weight: 700;
            }

            &-slogan{
                color: var(--primary-text-color);
                font-size: .9rem;
                font-weight: 500;
                width: 27ch;
                opacity: .7;
            }
        }

        &-links{
            display: flex;
            gap: 2rem;
            align-items: center;

            &-item{
                color: var(--secondary-text-color);
                font-size: .9rem;
                font-weight: 700;
            }
        }
    }

    &-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        

        & p {
            color: var(--third-text-color);
            font-size: .8rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            & a{
                color: var(--secondary-text-color);
                font-weight: 700;
            }
        }
       
    }
}

@media only screen and (max-width:767px) {
    .footer-wrap{
        &-content{
            flex-direction: column;
            gap: 2rem;

            &-texts{
                width: 100%;
            }

    
    
            &-links{
                flex-direction: column;
                align-items: start;
                width: 100%;
                gap: 1rem;
            }
        }
    
        &-footer{
            flex-direction: column;
            gap: 1rem;
            align-items: start;
           
        }
    }
}