.authentication-wrap {
    display: flex;
    flex-direction: column;
    padding: 10rem 3.625rem 5rem 3.625rem;
    justify-content: center;
    align-items: start;
    position: relative;
    background-color: var(--background-color);

    &-background {
        width: 100%;
        height: 100%;
        
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0.45;
        object-fit: cover;
    }

    &-content {
        width: 100%;
        height: calc(100vh - 7rem);
        display: flex;
        z-index: 2;
        max-width: 80rem;
    }

    &-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        margin-bottom: 5rem;
        width: 30rem;
        height: fit-content;

        &-header {
            display: flex;
            flex-direction: column;
            justify-content: start;
            width: 100%;
            gap: 2rem;
            &-title {
                width: 100%;
                text-align: left;
                color: var(--primary-text-color);

                & span {
                    color: var(--main-color);
                }
            }

            &-progress{
                height: .5rem;
                border-radius: 5rem;
                background-color: var(--card-color);
                width: 100%;
                overflow: hidden;

                &-bar{
                    background-color: var(--main-color);
                    height: 100%;
                    transition: width .4s;
                    width: 33%;
                    animation: width-anim .4s;

                    @keyframes width-anim {
                        from{
                            width: 0;
                        }

                        to {
                            width: 33%;
                        }
                    }


                    &.second{
                        width: 66%;
                    }

                    &.third{
                        width: 100%;
                    }
                }

            }
        }

        &-content {
            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 1rem;
            transition: width 0.4s;

            &.signup {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: .5rem;
            }

            & .input {
                & input{
                    border-color: var(--third-text-color);
                    background-color: transparent;
                    color: var(--primary-text-color);
                    color-scheme: dark;

                    &:-webkit-autofill {
                        transition: background-color 5000s ease-in-out 0s !important; /* Empêche l'effet de transition par défaut */
                        -webkit-text-fill-color: var(--primary-text-color) !important;
                    }

                    &::placeholder{
                        color: var(--third-text-color);
                    }
                }
                
                & p{
                    color: var(--secondary-text-color);
                }
            }
               
        }

        &-footer {
            display: flex;
            gap: 1rem;
            width: 100%;

            &-submit,
            &-back {
                display: flex;
                flex: 1;
                padding: 1rem;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                font-family: "DM Sans";
                font-size: 1rem;
                font-weight: 800;
                line-height: 1.375rem;
                border: none;
                outline: none;
                border-radius: var(--default-radius);

                &:hover {
                    cursor: pointer;
                }
            }

            &-submit {
                background: var(--main-color);
                color: var(--text-cta-color);

                &:hover{
                    background-color: var(--card-color);
                }
            }

            &-back {
                background: var(--card-color);
                color: var(--secondary-text-color);
            }
        }
    }


.signup-1{
    .authentication-wrap-card-header-progress-bar{
        width: 33%;
    }
}

.signup-2{
    .authentication-wrap-card-header-progress-bar{
        width: 66%;
    }
}

.signup-3{
    .authentication-wrap-card-header-progress-bar{
        width: 100%;
    }
}
    
.footer-wrap {
    z-index: 2;
    &-content-texts-title {
        color: var(--primary-text-color);
    }

    &-content-texts-slogan {
        color: var(--third-text-color);
        opacity: 0.7;
    }

    &-content-links-item {
        color: var(--secondary-text-color);
    }

    &-footer p {
        color: var(--third-text-color);
        opacity: 0.5;
    }
}
}

@media only screen and (max-width:767px) {
    .authentication-wrap{
        padding: 10rem 1.5rem 8rem 1.5rem;
    }
    .authentication-wrap-card-footer {
        &-submit {
            background: var(--main-color);
            color: var(--text-cta-color);

            &:hover{
                background: var(--main-color);
            }
        }

        &-back {
            background: var(--card-color);
            color: var(--secondary-text-color);
        }
    }
}


