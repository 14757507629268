.latest-articles,.most-popular {
    width: 100%;
    max-width: 100%;
    & .section-wrap-header{
        padding-left: 3.625rem;
    }
    & .section-wrap-content {
        gap: 1rem;
        transition: all 1s;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        width: calc(100%);
        display: grid;
        grid-auto-flow: column;
        
        & .article-card-wrap,& .skeleton-article-card-wrap {
            scroll-snap-align: center;
            width: 25rem;
            max-width: 50rem;
            flex: 0 0 25rem;

            &:first-child{
                padding-left: 3.625rem;
                width: calc(25rem + 3.625rem);
            }


            &:last-child{
                margin-right: 3.625rem;
            }
            
        }
    }
}

@media only screen and (max-width:767px) {
    
    .latest-articles,.most-popular {
        & .section-wrap-header {
            padding-left: 1.5rem;
        }
        & .section-wrap-content {
            gap: .75rem;
            & .article-card-wrap {
                min-width: 4rem;
                width: calc(95vw - 2*1.5rem);
                flex-grow: 1;

                &:first-child{
                    padding-left: 1.5rem;
                    width: calc(95vw - 1.5rem);
                }
    
    
                &:last-child{
                    margin-right: 1.5rem;
                }
                
            }
        }
    }
}